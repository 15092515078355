import { useMutation, useQueryClient } from '@tanstack/react-query';
import { acceptInvite } from 'api/api';
import { ADMIN_TENANT_ID, ROUTES } from '../../common/constants';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useAtom } from 'jotai';
import { tenantIdAtom } from 'tenant/useTenantId';
import { userId } from 'users/userId';
import { authToken } from 'auth/authToken';
import useAuthRedirect from 'auth/useAuthRedirect';

interface Loading {
  status: 'Loading';
}

interface Error {
  status: 'Error';
  message: string;
}

interface AdminInvite {
  status: 'AdminInvite';
}

const useAcceptInvitation = (): Loading | Error | AdminInvite => {
  const { formatMessage } = useIntl();
  const [params] = useSearchParams();
  const code = params.get('code');
  const tenantId = params.get('tenantId');
  const navigate = useNavigate();
  const [, setTenantId] = useAtom(tenantIdAtom);
  const location = useLocation();
  const [, setAuthRedirect] = useAuthRedirect();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['accept-invite'],
    mutationFn: async ({ tenantId, code }: { tenantId: string; code: string }) => acceptInvite(tenantId, code),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['tenants', userId] });
      setTenantId(tenantId ?? '');
      navigate(ROUTES.OVERVIEW, { replace: true });
    },
  });

  if (!authToken || !userId) {
    setAuthRedirect(location.pathname + '?' + new URLSearchParams(location.search).toString());
    navigate(ROUTES.LOGIN);
    return { status: 'Loading' };
  }

  if (!code || !tenantId) {
    return {
      status: 'Error',
      message: formatMessage({
        defaultMessage: 'Unable to find invite code',
        description: 'Unable to find invite code page title',
      }),
    };
  }

  if (code === ADMIN_TENANT_ID) {
    return { status: 'AdminInvite' };
  }

  if (mutation.status === 'idle') {
    mutation.mutate({ tenantId, code });
  }

  return { status: 'Loading' };
};

export default useAcceptInvitation;
