import GenericError from 'common/GenericError';
import DataRoutingLoadingSection from 'data-routing/DataRoutingLoadingSection';
import DataRoutingSetupSection from 'data-routing/DataRoutingSetupSection';
import DataRoutingSummarySection from 'data-routing/DataRoutingSummarySection';
import { SetupSubdomainModalProvider } from 'data-routing/SetupSubdomainModalProvider';
import React from 'react';
import { useIntl } from 'react-intl';
import assertUnreachable from 'util/assertUnreachable';
import useAdminSubdomainSetupStatus from './useAdminSubdomainSetupStatus';
import SelectDnsProvider from './SelectDnsProvider';
import CloudflareTokenInput from './CloudflareTokenInput';
import PageHeader from 'common/PageHeader';
import SetupIncompleteSection from 'data-routing/SetupIncompleteSection';

const Header: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <PageHeader
      title={formatMessage({
        defaultMessage: 'Data Routing',
        description: 'Data Routing page title',
      })}
    />
  );
};

const AdminSetup: React.FC = () => {
  const data = useAdminSubdomainSetupStatus();

  switch (data.status) {
    case 'Loading':
      return <DataRoutingLoadingSection />;
    case 'Error':
      return <GenericError />;
    case 'DnsTypeNotSetup':
      return (
        <div>
          <Header />
          <SelectDnsProvider admin={data.admin} />
        </div>
      );
    case 'CloudflareDnsTokenNotSetup':
      return (
        <div>
          <Header />
          <CloudflareTokenInput admin={data.admin} />
        </div>
      );
    case 'SubdomainNotSetup':
      return (
        <SetupSubdomainModalProvider>
          <div>
            <Header />
            <DataRoutingSetupSection />
          </div>
        </SetupSubdomainModalProvider>
      );
    case 'SubdomainNotActive':
      return (
        <SetupSubdomainModalProvider>
          <div>
            <Header />
            <SetupIncompleteSection subdomain={data.subdomain} />
          </div>
        </SetupSubdomainModalProvider>
      );
    case 'Setup':
      return (
        <SetupSubdomainModalProvider>
          <div>
            <Header />
            <DataRoutingSummarySection
              subdomain={data.subdomain}
              // TODO (rgreen): Add support for editing Admin tenant subdomain, at least in the case of Apprunner DNS setup
              readOnly
            />
          </div>
        </SetupSubdomainModalProvider>
      );
    default:
      return assertUnreachable(data);
  }
};

export default AdminSetup;
