import { useQuery } from '@tanstack/react-query';
import { getTenantConfig } from 'api/api';
import { useTenantId } from 'tenant';
import { fromJson } from 'translators/TenantConfigTranslator';
import { TenantConfig } from 'types';
import assertUnreachable from 'util/assertUnreachable';

interface Loading {
  status: 'Loading';
}

interface Error {
  status: 'Error';
}

interface Success {
  status: 'Success';
  tenantConfig: TenantConfig;
}

type Output = Loading | Error | Success;

const useTenantConfig = (): Output => {
  const tenantId = useTenantId();

  const tenantConfig = useQuery({
    queryKey: ['tenantConfig', tenantId],
    queryFn: async () => await getTenantConfig(tenantId),
  });

  switch (tenantConfig.status) {
    case 'pending':
      return { status: 'Loading' };
    case 'error':
      return { status: 'Error' };
    case 'success':
      return { status: 'Success', tenantConfig: fromJson(tenantConfig.data) };
    default:
      return assertUnreachable(tenantConfig);
  }
};

export default useTenantConfig;
