import { useTenantConfig } from 'tenant';
import assertUnreachable from 'util/assertUnreachable';

interface Error {
  status: 'Error';
}

interface Loading {
  status: 'Loading';
}

interface Setup {
  status: 'Setup';
  subdomain: string;
}

interface NotSetup {
  status: 'NotSetup';
}

interface SubdomainNotActive {
  status: 'SubdomainNotActive';
  subdomain: string;
}

type Output = Error | Loading | Setup | NotSetup | SubdomainNotActive;

const useSubdomainSetupStatus = (): Output => {
  const tenantConfig = useTenantConfig();

  switch (tenantConfig.status) {
    case 'Loading':
      return { status: 'Loading' };
    case 'Error':
      return { status: 'Error' };
    case 'Success': {
      if (!tenantConfig.tenantConfig.subdomain) {
        return {
          status: 'NotSetup',
        };
      }

      if (!tenantConfig.tenantConfig.isSubdomainActive) {
        return {
          status: 'SubdomainNotActive',
          subdomain: tenantConfig.tenantConfig.subdomain ?? '',
        };
      }

      return { status: 'Setup', subdomain: tenantConfig.tenantConfig.subdomain ?? '' };
    }
    default:
      return assertUnreachable(tenantConfig);
  }
};

export default useSubdomainSetupStatus;
