import { useQuery } from '@tanstack/react-query';
import { getDnsInfo, getPixelConfg } from 'api/api';
import { CONNECTED_PIXEL_STATUS_COOKIE_NAME_PREFIX } from 'common/constants';
import { useCookies } from 'react-cookie';
import { useTenantConfig, useTenantId } from 'tenant';
import assertUnreachable from 'util/assertUnreachable';

type Status = 'Connected' | 'Loading' | 'MismatchedSubdomain' | 'NoConfigFound' | 'Error' | 'RecentlyConnected';

const useConnectedPixelStatus = (pixelId: string): Status => {
  const tenantId = useTenantId();
  const tenantConfig = useTenantConfig();
  const [cookies] = useCookies();
  const data = useQuery({
    queryKey: ['pixelStatus', pixelId],
    queryFn: async () => await getPixelConfg(pixelId),
  });
  const dnsData = useQuery({
    queryKey: ['dnsInfo', tenantId],
    queryFn: async () => await getDnsInfo(tenantId),
  });
  const cookieData = cookies[`${CONNECTED_PIXEL_STATUS_COOKIE_NAME_PREFIX}${pixelId}`];

  switch (data.status) {
    case 'pending':
      return 'Loading';
    case 'error':
      return 'Error';
    case 'success': {
      const config = data.data.data;
      if (!config) {
        return 'Error';
      }

      if (!config.gw) {
        if (cookieData) {
          return 'RecentlyConnected';
        }

        return 'NoConfigFound';
      }

      switch (tenantConfig.status) {
        case 'Error':
          return 'Error';
        case 'Loading':
          return 'Loading';
        case 'Success': {
          switch (dnsData.status) {
            case 'error':
              return 'Error';
            case 'pending':
              return 'Loading';
            case 'success': {
              const dnsInfo = dnsData.data.data;
              if (dnsInfo.effective_domain !== config.gw.h) {
                return 'MismatchedSubdomain';
              }
              return 'Connected';
            }
            default:
              return assertUnreachable(dnsData);
          }
        }
        default:
          return assertUnreachable(tenantConfig);
      }
    }
    default:
      return assertUnreachable(data);
  }
};

export default useConnectedPixelStatus;
