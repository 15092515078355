import { useQuery } from '@tanstack/react-query';
import { getDnsInfo } from 'api/api';
import { DnsSetupType } from 'api/types';
import { ADMIN_TENANT_ID } from 'common/constants';
import useAdminConfig from 'tenant/useAdminConfig';
import { AdminConfig } from 'types';
import assertUnreachable from 'util/assertUnreachable';

interface Error {
  status: 'Error';
}

interface Loading {
  status: 'Loading';
}

interface Setup {
  status: 'Setup';
  subdomain: string;
  dnsSetupType: DnsSetupType;
}

interface DnsTypeNotSetup {
  status: 'DnsTypeNotSetup';
  admin: AdminConfig;
}

interface CloudflareDnsTokenNotSetup {
  status: 'CloudflareDnsTokenNotSetup';
  admin: AdminConfig;
}

interface SubdomainNotSetup {
  status: 'SubdomainNotSetup';
  admin: AdminConfig;
}

interface SubdomainNotActive {
  status: 'SubdomainNotActive';
  subdomain: string;
}

type Output =
  | Error
  | Loading
  | Setup
  | DnsTypeNotSetup
  | CloudflareDnsTokenNotSetup
  | SubdomainNotSetup
  | SubdomainNotActive;

const useAdminSubdomainSetupStatus = (): Output => {
  const adminConfig = useAdminConfig();

  const dnsInfo = useQuery({
    queryKey: ['getDnsInfo', ADMIN_TENANT_ID],
    queryFn: async () => await getDnsInfo(ADMIN_TENANT_ID),
  });

  switch (dnsInfo.status) {
    case 'pending':
      return { status: 'Loading' };
    case 'error':
      return { status: 'Error' };
    case 'success':
      switch (adminConfig.status) {
        case 'Loading':
          return { status: 'Loading' };
        case 'Error':
          return { status: 'Error' };
        case 'Success': {
          if (!adminConfig.admin.dnsSetupType) {
            return {
              status: 'DnsTypeNotSetup',
              admin: adminConfig.admin,
            };
          }

          if (adminConfig.admin.dnsSetupType === DnsSetupType.Cloudflare && !adminConfig.admin.cloudflareToken) {
            return {
              status: 'CloudflareDnsTokenNotSetup',
              admin: adminConfig.admin,
            };
          }

          if (!adminConfig.admin.subdomain) {
            return {
              status: 'SubdomainNotSetup',
              admin: adminConfig.admin,
            };
          }

          if (!adminConfig.admin.isSubdomainActive) {
            return {
              status: 'SubdomainNotActive',
              subdomain: adminConfig.admin.subdomain,
            };
          }

          return {
            status: 'Setup',
            subdomain: adminConfig.admin.subdomain ?? '',
            dnsSetupType: adminConfig.admin.dnsSetupType,
          };
        }
        default:
          return assertUnreachable(adminConfig);
      }
    default:
      return assertUnreachable(dnsInfo);
  }
};

export default useAdminSubdomainSetupStatus;
