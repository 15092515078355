export interface PixelConfigJson {
  pixel_id?: string;
  capi_token?: string;
  tenant_id?: string;
  name?: string;
}

export interface PixelServerPixelConfig {
  gw?: {
    h?: string;
  };
}

export interface DeletePixelInputJson {
  pixel_id?: string;
  tenant_id?: string;
}

export enum TenantStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface TenantConfigJson {
  tenant_id?: string;
  subdomain?: string;
  name?: string;
  is_subdomain_active?: boolean;
  status?: TenantStatus;
}

export interface AdminConfigJson extends TenantConfigJson {
  dns_setup_type?: DnsSetupType;
  cloudflare_token?: string;
}

export enum DnsRecordType {
  A = 'A',
  CNAME = 'CNAME',
  TXT = 'TXT',
}

export interface DnsRecord {
  name?: string;
  type?: DnsRecordType;
  value?: string;
  status?: string;
}

export interface DnsInformation {
  dns_setup_type?: DnsSetupType;
  is_custom_hostname_ready: boolean;
  dns_records?: DnsRecord[];
  effective_domain?: string;
}

export interface EventOverviewJson {
  total_events_received?: number;
  total_events_sent?: number;
  event_types_received?: number;
  event_types_sent?: number;
  time_since_received?: number;
  time_since_sent?: number;
}

export interface PixelEventsData {
  pixel_id?: string;
  event_types?: EventTypeData[];
}

export interface EventTypeData {
  event_type?: string;
  failed_count?: number;
  success_count?: number;
  time_since_received?: number;
  time_since_sent?: number;
}

export enum Role {
  Member = 'MEMBER',
  Admin = 'ADMIN',
}

export enum DnsSetupType {
  Aws = 'APPRUNNER',
  Cloudflare = 'CLOUDFLARE',
}

export interface UserJson {
  user_id?: string;
  tenant_id?: string;
  name?: string;
  role?: Role;
  status?: 'ACTIVE' | 'INVITED';
}

export interface UserTenantRoleJson {
  tenant_id?: string;
  role?: Role;
  user_id?: string;
  user_name?: string;
  tenant_name?: string;
}

// Date range input to events related endpoints. The dates are in milliseconds UTC time.
export interface DateRangeInputJson {
  from?: number;
  to?: number;
}

export interface UserInviteJson {
  role?: Role;
  invite_url?: string;
  name?: string;
}

export interface ApiTokenJson {
  name?: string;
  id?: string;
  value?: string;
}
