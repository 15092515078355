import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Dispatch, useContext, useEffect } from 'react';
import { Event, State } from './useAddTenantModalReducer';
import { createTenant } from 'api/api';
import { Notification } from '@snapchat/snap-design-system';
import { useIntl } from 'react-intl';
import { userId } from 'users/userId';

const useCreateTenantEffect = (dispatch: Dispatch<Event>, state: State) => {
  const { error } = useContext(Notification.Context);
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['createTenant'],
    mutationFn: async () => await createTenant(state.tenantName),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['tenants', userId]});
      dispatch({ type: 'CreateTenantSuccess', newTenantId: data.data.tenant_id ?? '' })
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ['tenants'] });
      dispatch({ type: 'CreateTenantError' });
      error({
        title: formatMessage({
          defaultMessage: 'Failed to create new account.',
          description: 'Failed to create tenant error message',
        }),
      });
    },
  });

  useEffect(() => {
    if (state.createTenantStatus === 'Ready') {
      dispatch({ type: 'CreateTenantStarted' });
      mutation.mutate();
    }
  }, [state.createTenantStatus, dispatch, mutation]);
};

export default useCreateTenantEffect;
