import PageHeader from 'common/PageHeader';
import React from 'react';
import { useIntl } from 'react-intl';
import DataRoutingSetupSection from './DataRoutingSetupSection';
import { SetupSubdomainModalProvider } from './SetupSubdomainModalProvider';
import DataRoutingLoadingSection from './DataRoutingLoadingSection';
import assertUnreachable from 'util/assertUnreachable';
import DataRoutingSummarySection from './DataRoutingSummarySection';
import useSelectedTenant from 'tenant/useSelectedTenant';
import isAuthorized from 'auth/isAuthorized';
import { Section } from '@snapchat/snap-design-system';
import GenericError from 'common/GenericError';
import useSubdomainSetupStatus from './useSubdomainSetupStatus';
import { useTenantId } from 'tenant';
import { ADMIN_TENANT_ID } from 'common/constants';
import AdminSetup from './AdminSetup';
import SetupIncompleteSection from './SetupIncompleteSection';
import { DeleteSubdomainModalProvider } from './DeleteSubdomainSetupModalProvider';

const DataRoutingPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { role } = useSelectedTenant();
  const data = useSubdomainSetupStatus();
  const tenantId = useTenantId();
  const canEdit = isAuthorized({ role, entity: 'Tenant', action: 'Update' });
  const notAuthorizedContent = (
    <div>
      <PageHeader
        title={formatMessage({
          defaultMessage: 'Data Routing',
          description: 'Data Routing page title',
        })}
      />
      <Section>
        <div>
          <h4>
            {formatMessage({
              defaultMessage:
                "You don't have permission to update the subdomain for your Conversions API Gateway, please contact your administrator",
              description: 'Unauthorized title',
            })}
          </h4>
        </div>
      </Section>
    </div>
  );

  if (tenantId === ADMIN_TENANT_ID) {
    return <AdminSetup />;
  }

  switch (data.status) {
    case 'Loading':
      return <DataRoutingLoadingSection />;
    case 'Error':
      return <GenericError />;
    case 'Setup':
      return (
        <DeleteSubdomainModalProvider>
          <div>
            <PageHeader
              title={formatMessage({
                defaultMessage: 'Data Routing',
                description: 'Data Routing page title',
              })}
            />
            <DataRoutingSummarySection subdomain={data.subdomain} />
          </div>
        </DeleteSubdomainModalProvider>
      );
    case 'SubdomainNotActive': {
      if (!canEdit) {
        return notAuthorizedContent;
      }

      return (
        <SetupSubdomainModalProvider>
          <DeleteSubdomainModalProvider>
            <div>
              <PageHeader
                title={formatMessage({
                  defaultMessage: 'Data Routing',
                  description: 'Data Routing page title',
                })}
              />
              <SetupIncompleteSection subdomain={data.subdomain} />
            </div>
          </DeleteSubdomainModalProvider>
        </SetupSubdomainModalProvider>
      );
    }
    case 'NotSetup': {
      if (!canEdit) {
        return notAuthorizedContent;
      }

      return (
        <SetupSubdomainModalProvider>
          <div>
            <PageHeader
              title={formatMessage({
                defaultMessage: 'Data Routing',
                description: 'Data Routing page title',
              })}
            />
            <DataRoutingSetupSection />
          </div>
        </SetupSubdomainModalProvider>
      );
    }
    default:
      return assertUnreachable(data);
  }
};

export default DataRoutingPage;
